$(window).load(function(){
	$('body').removeClass('startup_loading');

	if($('body').hasClass('new-homes')){
/*
		$('.list-menu li:not(.on)').each(function(){
			var $li = $(this);
			//$li.toggleClass("loading");

			var layout = $(this).data('layout'),
				page_id = $(this).data('page-id');

			$.ajax({
				type: 'POST',
				url: '/pages/layouts/new-homes/'+layout+'.php',
				data:({page_id:page_id}),
				dataType: 'html',
				success: function(html) {
					$('.main-part ').append(html);

					$li.toggleClass("loading");

					if(layout=="all_homes"){
						ini_new_homes_all_homes();
						number_new_homes_showing();

					}else if(layout=="collections_featured"){
						ini_new_homes_collections()
					}
					setTimeout(function() {
				   	 $(window).lazyLoadXT();
				  	}, 50);


				}
			});


		});
*/




	}


});

$(document).ready(function(){
	$(window).on('ajaxComplete', function() {
	  setTimeout(function() {
	    $(window).lazyLoadXT();
	  }, 50);
	});
		//---------- gallery page --------//
		if ($('body.gallery').length > 0) {
			var $grid = $('body.gallery .grid').isotope({
			  itemSelector: '.grid-item',
			  percentPosition: true,
			  layoutMode: 'packery',
			  packery: {
			    columnWidth: '.grid-item.small',
			    gutter: '.gutter-sizer',

			  }
			});
		}


		$('body.gallery .filters .label-radio label').click(function(e){

			if($(this).hasClass('disabled')){
				e.preventDefault();
				return false;
			}else{
				var input_id = $(this).attr('for'),
					empty_input = $(this).parent().find(".empty");


				if($('#'+input_id).is(":checked")){
					e.preventDefault();

					$('#'+input_id).prop("checked", false);

					empty_input.prop("checked", true).trigger("change");

				}

			}

		});



		function overlay_slider_arrow_container_height_sizer(){
/*
			setTimeout(function(){

				if($('#modal-gallery .large_image .image_wrapper.active .l_image_container img').height() > 0){

					$('#modal-gallery .slider-arrow-styles').css('height',$('#modal-gallery .large_image .image_wrapper.active .l_image_container img').height());
				}else{
					overlay_slider_arrow_container_height_sizer()
				}
			}, 200);
*/

		}

		$(window).on('resize orientationchange', function() {
		  setTimeout(function(){

		  	   var current = $("#modal-gallery .slider-images-secondary__slides").slick('slickCurrentSlide');
		  	   $("#modal-gallery .slider-images-secondary__slides").slick('slickGoTo',current);
		  	   //console.log(current);
		  }, 500);
		});
		$("#modal-gallery .large_image").on("click", ".play-vimeo", function(e){
				var parent = $(this).parents(".image_wrapper"),
					image = parent.find("img:not(.play-vimeo)"),
					iframe_container = parent.find(".iframe_container"),
					iframe = iframe_container.find('iframe');

					iframe_container.css("width",image.width()).css("height",image.height());
					iframe.attr('src',iframe.attr("data-src"));
				;
				parent.find(".play-vimeo").fadeOut(300, function(){
					iframe_container.addClass('active');
				});
		});

		$('body.gallery .ajax-content-here').on("click", ".grid-item", function(e){

			if($(e.target).hasClass('play-vimeo')){
				var iframe = $(this).find("iframe");
					iframe.attr('src',iframe.attr("data-src"));

				$(this).find(".play-vimeo").fadeOut(300, function(){
					iframe.addClass('active');
				});

			}else if(!$(e.target).hasClass('pinterest_button') && !$(e.target).hasClass('fa-pinterest')){

				var image_id = $(this).data("image-id");


				if($(window).width() > 767){
					var show_first_big_image = $('#modal-gallery .large_image .image_wrapper[data-image-id='+image_id+'] img:not(.play-vimeo)');
					show_first_big_image.attr("src",show_first_big_image.data("src"));
					show_first_big_image.parents(".image_wrapper").addClass("active");
					overlay_slider_arrow_container_height_sizer(image_id);
				}

		        if (image_id) {

		             var slick_index = $('#modal-gallery .slick-slide[data-image-id=' + image_id + ']').data('slick-index');
		             //console.log(page_id, slick_index);
		            if (slick_index != undefined && $('#modal-gallery .slick-slide.slick-current.slick-active').data('slick-index') != slick_index) {
			            $('#modal-gallery .slick-slider').slick('slickSetOption', 'adaptiveHeight', false);
		                $('#modal-gallery .slick-slider').slick('slickGoTo', slick_index, false);
			            if($(window).width() <= 767){
				           $('#modal-gallery .slider-images-secondary').css("opacity",0);
				           $('#modal-gallery .slick-slider').slick('slickSetOption', 'adaptiveHeight', true);
			            }

						//overlay_slider_arrow_container_height_sizer(image_id);
		            }
		        }

				if($(window).width() > 767){

					var slides = $('#modal-gallery .slider-images-secondary .slick-slide:not(.slick-cloned)');

					if(slides.length == 1){
						$('#modal-gallery .slick-slider, #modal-gallery .slick-arrow').hide();
					}else{
						$('#modal-gallery .slick-slider, #modal-gallery .slick-arrow').show();
					}

					if(slides.width()*slides.length > $(window).width()){
						//$('#modal-gallery .slick-slider').slick('slickSetOption', 'centerMode',false,false);
						$('#modal-gallery .slick-slider').slick('slickSetOption', 'infinite', true,true);
					}else{
						$('#modal-gallery .slick-slider').slick('slickSetOption', 'centerMode',true,false);
						$('#modal-gallery .slick-slider').slick('slickSetOption', 'infinite', false,true);


					}


					$('#modal-gallery .slider-images-secondary .img-container div.image').each(function(){

						$(this).css("background-image","url("+$(this).data("src")+")");
					});
				}else{
						$('#modal-gallery .slider-images-secondary').fadeTo(300,1);

				}



				$('#modal-gallery').modal();
				//overlay_slider_arrow_container_height_sizer();
			}
		});


		$('#modal-gallery .slider-images-secondary__slides.images').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			var image_id = $('#modal-gallery .slick-slide:not(.slick-cloned)[data-slick-index=' + nextSlide + ']').data('image-id');


			if($(window).width() > 767){
				var active_image_container_img = $('#modal-gallery .large_image .image_wrapper[data-image-id='+image_id+'] img:not(.play-vimeo)');
				if(active_image_container_img.hasClass("loaded")){
					$('#modal-gallery .large_image').fadeTo(300,0,function(){
						$('#modal-gallery .large_image .image_wrapper.active').removeClass("active");
						active_image_container_img.parents('.image_wrapper').addClass("active");

						$('#modal-gallery .large_image').fadeTo(300,1);
						overlay_slider_arrow_container_height_sizer(image_id);
					})
				}else{
					$('#modal-gallery .large_image').fadeTo(500,0);
					load_bg_images(active_image_container_img.data("src") , function(){
						active_image_container_img.attr("src",active_image_container_img.data("src")).addClass('loaded');


						$('#modal-gallery .large_image .image_wrapper.active').removeClass("active");
						active_image_container_img.parents('.image_wrapper').addClass("active");

							$('#modal-gallery .large_image').fadeTo(300,1);
							overlay_slider_arrow_container_height_sizer(image_id);

					});
					$('#modal-gallery .large_image .image_wrapper:not(.active) img').each(function(){
						var temp_image = $(this);
						load_bg_images(temp_image.data("src") , function(){
							temp_image.attr("src",temp_image.data("src")).addClass('loaded');

						});
					});

				}
				//overlay_slider_arrow_container_height_sizer(image_id);
			}else{
				var active_text_container = $('#modal-gallery .mobile-text-slider .text_container[data-image-id='+image_id+']');
				$('#modal-gallery .mobile-text-slider .container').fadeTo(200,0,function(){
					$('#modal-gallery .mobile-text-slider .text_container.active').removeClass("active");
					active_text_container.addClass("active");
					$('#modal-gallery .mobile-text-slider .container').fadeTo(200,1);
				});
			}


		});

		$('.gallery .main-part').on('click','.load-more',function(){
			var button = $(this),
				old_text = button.text();

				$('.houses-filter__sidebar .filter-prevent-overlay').addClass("on");
				button.text("Loading...");
			$.ajax({
				type: 'POST',
				url: '/ajax/load_more_gallery.php',
				dataType: 'json',
				success: function(html) {


					if(parseInt(html.load_more) > 0){
						$('button.load-more').removeClass('hidden').attr("data-count",parseInt(html.load_more));
					}else{
						$('button.load-more').addClass('hidden').attr("data-count",0);
					}


					insert_grid_item_div_with_load(html.html);
					$('#modal-gallery .large_image').append(html.modal_large_images);
					$.each(html.modal_slider, function(key,value){

						$('#modal-gallery .slider-images-secondary__slides').slick('slickAdd',value);
					});
					$.each(html.text_container_array, function(key,value){

						$('#modal-gallery .mobile-text-slider .container').append(value);
					});


					button.text(old_text);


				}
			});
		});

		$('#modal-gallery .slider-arrow-styles .slick-arrow').click(function(){

			$('#modal-gallery .slider-images-secondary button.'+$(this).attr('class').split(' ').join('.')).click();
		});

		$('.gallery').on('mouseenter','.grid .grid-item',function() {
			    $('.grid').addClass("hover-grid-item");
			});

		$('.gallery').on('mouseleave','.grid .grid-item'	, function() {
			    $('.grid').removeClass("hover-grid-item");
			}
		);

		function check_multiple_filters_ajax(page_id, filter_array, close_overlay){
			$.ajax({
				type: 'POST',
				url: '/ajax/gallery_filter_check.php',
				data:({filter:filter_array, page_id:page_id}),
				dataType: "json",
				success: function(html) {

					if(html.filter_array_check != undefined){
/*
						//updating filters
						if(html.series_select_html != undefined && html.series_select_html != "" ){
							$("select[name=series]").html(html.series_select_html);
						}
*/

						$.each(html.filter_array_check, function(key, value){
							$('.custom_filter_check input[name="'+ value.name +'"]').val(value.used.join("|"));
						});
						gallery_custom_filter_check(close_overlay);
					}

				}
			});

		}
		function addNewListItem($select, $ele) {


		    // append new element
		    $select.append($ele);

		    // get all elements
		    var selectItems = $select.children('option').not(".default").get();
			//console.log(selectItems, $select.children('option'));
		    // sort elements by contents
		    selectItems.sort(function(a, b) {
		       return $(a).text().toUpperCase().localeCompare($(b).text().toUpperCase());
		    });
		    $.each(selectItems, function(idx, itm) { $select.append(itm); });


		}

		function gallery_custom_filter_check(close_overlay){
			$(".gallery .custom_filter_check input").each(function(){
				var html_type = $(this).attr('data-html-type'),
					html_name = $(this).attr('name'),
					values = $(this).val().split('|');

					if(html_type == "select"){
							var temp_select = $('.filters select[name="' + html_name + '"]');
							temp_select.find("option").not(":first-of-type").not(":selected").not(".default").each(function(){
								//$(this).removeClass("on").prop("disabled",true);
								$(this).remove();
							});
					}else if(html_type == "radio"){
							var parent = $('.filters input[name="'+html_name+'"]').parent();
							parent.find('label:not(.not-disabled)').addClass("disabled");
					}

					$.each(values,function(key,value){
						if(html_type == "select"){
							if(value != ""){
								var temp_span = temp_select.parents(".custom-select").find('.option_clones span[data-id="'+ value +'"]');

								if(temp_select.find("option:selected").attr("data-id") != temp_span.attr("data-id")){
									//temp_select.append("<option value='"+temp_span.attr("data-value")+"' data-id='"+temp_span.attr("data-id")+"' class='on'>"+temp_span.attr("data-label")+"</option>");
									addNewListItem(temp_select, $("<option value='"+temp_span.attr("data-value")+"' data-id='"+temp_span.attr("data-id")+"' class='on'>"+temp_span.attr("data-label")+"</option>"));
								}

								//temp_select.find('option[data-id="'+ value +'"]').addClass("on").prop("disabled",false);


							}

						}else if(html_type == "radio"){
							parent.find('label[for="'+html_name+'_' + value + '"]').removeClass("disabled");
						}
					})

					if($('.filters select[name="' + html_name + '"] option.on').length == 0){
						$('.filters select[name="' + html_name + '"] option:first-child').text("No match found").prop('selected', true);
					}else{
						$('.filters select[name="' + html_name + '"] option:first-child').text($('.filters select[name="' + html_name + '"] option:first-child').attr('data-text'))
					}
			});

			if(close_overlay == "true"){
				$('.houses-filter__sidebar .filter-prevent-overlay').hide();
			}
		}
		gallery_custom_filter_check();
		//---------- inclusions --------//

	$('.litetooltip-hotspot-wrapper .hotspot').each(function () {
		//console.log($(this));
	    $(this).LiteTooltip({ title: $(this).find('.data-container').html() });
	 });

	 	function check_hotspot_ajaxing(hotspot_container, currentSlide, nextSlide) {
		    if(hotspot_container.hasClass("ajaxing")){
		        setTimeout(function(){
				  check_hotspot_ajaxing(hotspot_container, currentSlide, nextSlide);
				}, 300);
		    }else{
		       	change_hotspot_image(hotspot_container, currentSlide, nextSlide);
		    }
		}

		function change_hotspot_image(hotspot_container, currentSlide, nextSlide){

			hotspot_container.addClass("ajaxing").css("height",hotspot_container.height());
			var image_id = $('.inclusions .slick-slide[data-slick-index='+nextSlide+']').data('image-id');
			//accordion
			$('.accordion-secondary').fadeTo(300,0);

			//image
			hotspot_container.fadeTo(300,0,function(){

				$('.accordion-secondary, .article .image_caption').removeClass('active');
				hotspot_container.find('.image_wrapper').removeClass('active');

				if(hotspot_container.find('.image_wrapper[data-image-id='+image_id+']').length > 0){
					hotspot_container.find('.image_wrapper[data-image-id='+image_id+']').addClass('active');

					$('.accordion-secondary[data-image-id='+ image_id +'], .article .image_caption[data-image-id='+ image_id +']').addClass("active").fadeTo(500,1);

					hotspot_container.fadeTo(500,1,function(){
						hotspot_container.css("height","").removeClass("ajaxing");
					});

				}else{
					$.ajax({
						type: 'POST',
						url: '/ajax/inclusions_get_image.php',
						data:({image_id:image_id}),
						dataType: 'html',
						success: function(html) {
							hotspot_container.append(html);
							$('.litetooltip-hotspot-wrapper .hotspot').each(function () {
								//console.log($(this));
							    $(this).LiteTooltip({ title: $(this).find('.data-container').html() });
							});

							$('.accordion-secondary[data-image-id='+ image_id +'], .article .image_caption[data-image-id='+ image_id +']').addClass("active").fadeTo(500,1);
							hotspot_container.fadeTo(500,1,function(){
								hotspot_container.css("height","").removeClass("ajaxing");
							});


						}
					});
				}


			});

		}


		$('.inclusions .slider-images-secondary__slides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
			var hotspot_container = $('#hotspot_image');


			if(hotspot_container.hasClass("ajaxing")){
				check_hotspot_ajaxing(hotspot_container, currentSlide, nextSlide);

			}else{
				change_hotspot_image(hotspot_container, currentSlide, nextSlide);
			}

		});





	//---------- house and land --------//
	$('.house-and-land-landing-page .main-part').on('change','.suburb_select',function(){
		var suburb = $(this).val();
		if(!$('body').hasClass('general')){
		$.ajax({
			type: 'POST',
			url: '/ajax/suburbs_get_estates.php',
			data:({suburb:suburb}),
			dataType: 'json',
			success: function(response) {
				$('.estate_select').html('');
				$('.estate_select').append('<option value="">Any</option>');
				var selected = "";
				$.each(response.results, function(index,value){
					if(value.checked){var selected = "selected";}else{selected = "";}
					$('.estate_select').append('<option '+selected+' value="'+value.estate_id+'">'+value.estate_name+'</option>')
				});

				// sort the estate names
				var $estateSelect = $('.estate_select'),
					currentlySelected = $estateSelect.val(),
					$optsList = $estateSelect.find('option[value!=""]');
				$optsList.sort(function(a, b) {
					return $(a).text() > $(b).text() ? 1 : -1;
				});
				$estateSelect.empty().append('<option value="">Any</option>').append($optsList);
				$estateSelect.val(currentlySelected);
			}
		});
		}
	});
	$('.house-and-land-landing-page .main-part').on('change','.estate_select',function(){
		var estate = $(this).val();
		if(!$('body').hasClass('general')){
		$.ajax({
			type: 'POST',
			url: '/ajax/estates_get_suburbs.php',
			data:({estate:estate}),
			dataType: 'json',
			success: function(response) {
				$('.suburb_select').html('');
				$('.suburb_select').append('<option value="">Any</option>');
				var selected = "";
				$.each(response.results, function(index,value){
					if(value.checked){var selected = "selected";}else{selected = "";}
					$('.suburb_select').append('<option '+selected+' value="'+value.suburb+'">'+value.suburb+'</option>')

				});
			}
		});
		}
	});

	
	
	$('body.readybuilt-landing').on('change', '.suburb_select_custom', function() {
		var thisSuburb = $(this).val();
		var encodedSuburb = encodeURIComponent(thisSuburb);
	
		$.ajax({
			type: 'POST',
			url: '/ajax/rb_suburbs_estates.php',
			data: ({ region: '', suburb: thisSuburb }),
			dataType: 'json',
			success: function(response) {
				if (response.status == 'ok') {
					if (Object.keys(response.results.estates).length > 0) {
						// clear out the estates list
						$('.estate_select_custom').html('');
						$('.estate_select_custom').append('<option value="">Any</option>');
						$.each(response.results.estates, function(index, thisEstate) {
							$('.estate_select_custom').append('<option value="' + thisEstate.estate + '">' + thisEstate.estate + '</option>');
						});
					}

					if(encodedSuburb.length > 3){
						window.location.href = 'https://plantationhomes.com.au/homes-for-sale?rb_suburb='+ encodedSuburb;
					}
					else {
						
						$('.filters-advanced-custom-reset').trigger('click');
		
					}

	 					

					
				}
			}
		});
	});
	
	$('body.readybuilt-landing').on('change', '.estate_select_custom', function() {
		
		var thisEstate = $(this).val();
		var encodedEstate = encodeURIComponent(thisEstate);
		
		var thisSuburb = $('.suburb_select_custom').val();
		var encodedSuburb = encodeURIComponent(thisSuburb);

		if(encodedSuburb.length > 0){
			if(encodedEstate.length > 0){
				window.location.href = 'https://plantationhomes.com.au/homes-for-sale?rb_suburb='+ encodedSuburb + '&rb_estate=' + encodedEstate;
				
			} else {
				window.location.href = 'https://plantationhomes.com.au/homes-for-sale?rb_suburb='+ encodedSuburb;
			}
		

		} else {
			if(encodedEstate.length > 0){
					window.location.href = 'https://plantationhomes.com.au/homes-for-sale?rb_estate=' + encodedEstate;
					
				
				} else {
					$('.filters-advanced-custom-reset').trigger('click');
				}
			
			
		}


	});
	
	
	$('.readybuilt-landing').on('click','.filters-advanced-custom-reset',function(e){
		e.preventDefault();
		
		var response_type = "html";
		var ajax_file = '/pages/components/readybuilt__grid';

		var	page_layout = $("main").data("layout"),
			page_id = $("main").data("id");

		$.ajax({
			type: 'POST',
			url: ajax_file+'.php',
			data:({filter:[{value:"RESET", page_layout:page_layout, page_id:page_id}]}),
			dataType: response_type,
			success: function(html) {
				
				$('.ajax-content-here').html(html);
				number_new_homes_showing();

				//reset select boxes
				
				$('.suburb_select_custom')[0].selectedIndex = 0;
				$('.estate_select_custom')[0].selectedIndex = 0;
			

				//unhide options
				$(".filters select option").removeClass('hide');



				//remove all estates on h&l
				//$('.suburb_select  option:first-child').attr("selected", "selected");
				//$('.suburb_select_custom, .estate_select_custom').val("").trigger("change");

				var selectedSort = sort_selected();
				if (selectedSort.length > 0) history.pushState(null, null, '?sort=' + selectedSort);
				else history.pushState(null, null, window.location.protocol + '//' + window.location.hostname + window.location.pathname);
				window.location.reload();
	
			}
		});

		return false;
	});
	
	
	$('body.house-and-land-landing-page').on('change', '.suburb_select_custom', function() {
		var suburb = $(this).val();
		var encodedSuburb = encodeURIComponent(suburb);
		$.ajax({
			type: 'POST',
			url: '/ajax/suburbs_get_estates.php',
			data:({suburb:suburb}),
			dataType: 'json',
			success: function(response) {
				$('.estate_select_custom').html('');
				$('.estate_select_custom').append('<option value="">Any</option>');
				var selected = "";
				$.each(response.results, function(index,value){
					if(value.checked){var selected = "selected";}else{selected = "";}
					$('.estate_select_custom').append('<option '+selected+' value="'+value.estate_id+'">'+value.estate_name+'</option>')
				});

				// sort the estate names
				var $estateSelect = $('.estate_select_custom'),
					currentlySelected = $estateSelect.val(),
					$optsList = $estateSelect.find('option[value!=""]');
				$optsList.sort(function(a, b) {
					return $(a).text() > $(b).text() ? 1 : -1;
				});
				$estateSelect.empty().append('<option value="">Any</option>').append($optsList);
				$estateSelect.val(currentlySelected);
				
				if(encodedSuburb.length > 3){
					window.location.href = 'https://plantationhomes.com.au/house-and-land-packages?suburb='+ encodedSuburb;
				}
				else {
					$('.filters-advanced-custom-reset').trigger('click');
				}

			}
		});
		
	});
	
	$('body.house-and-land-landing-page').on('change', '.estate_select_custom', function() {
		
		var thisEstate = $(this).val();
		var encodedEstate = encodeURIComponent(thisEstate);
		
		var thisSuburb = $('.suburb_select_custom').val();
		var encodedSuburb = encodeURIComponent(thisSuburb);

		if(encodedSuburb.length > 0){
			if(encodedEstate.length > 0){
				window.location.href = 'https://plantationhomes.com.au/house-and-land-packages?suburb='+ encodedSuburb + '&estate=' + encodedEstate;
				
			} else {
				window.location.href = 'https://plantationhomes.com.au/house-and-land-packages?suburb='+ encodedSuburb;
			}
		

		} else {
			if(encodedEstate.length > 0){
					window.location.href = 'https://plantationhomes.com.au/house-and-land-packages?estate=' + encodedEstate;
						
				} else {
					$('.filters-advanced-custom-reset').trigger('click');
				}

		}
	});
	
	
	$('.house-and-land-landing-page').on('click','.filters-advanced-custom-reset',function(e){
		e.preventDefault();
		
		var response_type = "html";
		var ajax_file = '/pages/components/house-and-land__grid';

		var	page_layout = $("main").data("layout"),
			page_id = $("main").data("id");

		$.ajax({
			type: 'POST',
			url: ajax_file+'.php',
			data:({filter:[{value:"RESET", page_layout:page_layout, page_id:page_id}]}),
			dataType: response_type,
			success: function(html) {
				
				$('.ajax-content-here').html(html);
				number_new_homes_showing();

				//reset select boxes
				
				$('.suburb_select_custom')[0].selectedIndex = 0;
				$('.estate_select_custom')[0].selectedIndex = 0;
			

				//unhide options
				$(".filters select option").removeClass('hide');



				//remove all estates on h&l
				//$('.suburb_select  option:first-child').attr("selected", "selected");
				//$('.suburb_select_custom, .estate_select_custom').val("").trigger("change");

				var selectedSort = sort_selected();
				if (selectedSort.length > 0) history.pushState(null, null, '?sort=' + selectedSort);
				else history.pushState(null, null, window.location.protocol + '//' + window.location.hostname + window.location.pathname);
				window.location.reload();
	
			}
		});

		return false;
	});

	
	
	

	//load more
	$('.house-and-land-landing-page .main-part').on('click','.load-more',function(){
		fadeLoader('in');
		$.ajax({
			type: 'POST',
			url: '/ajax/load_more_house_and_land.php',
			dataType: 'html',
			success: function(html) {
				var property_count_before_load = $('.ajax-content-here .property').length,
					load_more_button = $('.button-gold.load-more'),
					load_more_count = load_more_button.data("count");

				$('.load_more_ajax').append(html);
				$('.load_more_ajax .property').slideDown(600,function(){
					var property_count_after_load = $('.ajax-content-here .property').length;
					load_more_button.data('count',load_more_count - (property_count_after_load-property_count_before_load));
					Waypoint.refreshAll();
					number_new_homes_showing();
				});
				fadeLoader('out');
			}
		});
	});

	$('.houses-filter__map-controls .radio__label').click(function(){
			if($('.houses-filter__panel-switch').hasClass('ajaxing')){
				return false;
			}

			$('.houses-filter__panel-switch').addClass('ajaxing');

			// $('.tabs-tertiary__tab-active').fadeTo( 500, 0.2 );
			fadeLoader('in');

			$(this).toggleClass('checked');
			var value = "";

			if($(this).hasClass('checked')){
				value = "show";
			}else{
				value = "dont_show";
			}


			$.ajax({
					//'async': false,
					'type': 'POST',
					'global': false,
					'url': "/resources/data/productrange-mapdata.php",
					'data':({display_center:value}),
					'dataType': 'json',
					success: function(response) {
						//clear mark and clusters
						$('.infoBox').remove();
						if(clusterer != undefined){
							clusterer.clearMarkers();
						}

			            markers = [];

						set_markers(response,true);

						// $('.tabs-tertiary__tab-active').fadeTo( 500, 1 );
						fadeLoader('out');
						$('.houses-filter__panel-switch').removeClass('ajaxing');
					}
			});

	});

	//----------- new homes -----------//
	ini_new_homes_all_homes();

	ini_new_homes_collections();

/*
	$('body.new-homes .list-menu li a').click(function(e){
		e.preventDefault();

		close_all_previews();
		$('.property__view-more').html("");
		$('.property-item__tail___preview-btn').removeClass("ajaxed");

		if(!$(this).parent().hasClass("on")){
			check_new_homes_section_loading($(this));
		}



		return false;
	})
*/


	var check_new_homes_section_loading = function(self){

		var $parent = self.parent();

		if(!$parent.hasClass("loading")){
			$('.list-menu li').removeClass("on")
			$parent.addClass("on");

			var layout = $parent.data('layout');
			$('.main-part').fadeOut(400,function(){
				$('.main-part .container').addClass("dont_show_page");
				$('.main-part .container[data-layout='+layout+']').removeClass("dont_show_page");

				//console.log($('.main-part .container[data-layout='+layout+']'));
				//History.replaceState({state:self.data("state")}, layout, "/new-homes-" + layout);
			}).fadeIn(400,function(){
				Waypoint.refreshAll();

			});

		}
	    else {
	        setTimeout(function() { check_new_homes_section_loading(self);}, 200)
	    }
	}


	$('.main-part, .display-centre-single .section-info').on("click",".property:not(.no_click)",function(e){
		if (typeof $(this).data('href') != 'undefined') {
			if($(e.target).hasClass("slide-down-preview") || $(e.target).hasClass("property-item__view-all") || $(e.target).hasClass("button__see-terms") || $(e.target).hasClass('property-item__tail--threesixty-view')){
			}
			else {
				e.preventDefault();
				window.location.href = $(this).data("href");
				return false;
			}
		}
	});

	//sorting
	$('.new-homes .main-part, .house-and-land-landing-page .main-part, .readybuilt-landing .main-part, .display-for-sale .main-part').on('change','.houses-filter__sort-by',function(){
		if($('.houses-filter__list .houses-filter__map-switch').hasClass('active') || $('.houses-filter__panel-switch').hasClass('ajaxing')){
			return false;
		}



		// $('.ajax-content-here').fadeTo( 500, 0.2 ).css("height",$('.ajax-content-here').height());
		var $ajaxContentHere = $('.ajax-content-here');
		var $thisObj = $(this);
		/*
		$('.loader-panel').fadeIn(function() {
			$ajaxContentHere.css('height', $ajaxContentHere.height());
		});
		*/
		fadeLoader('in', function() {
			$ajaxContentHere.css('height', $ajaxContentHere.height());
		});

		if($('body').hasClass('house-and-land-landing-page') || $('body').hasClass('readybuilt-landing')){
			var ajax_file = $('.tabs-tertiary__tab-active').data("file");
		}else{
			var ajax_file = '/pages/components/'+$('.ajax-content-here').data("file");
		}

		$('.houses-filter__panel-switch').addClass('ajaxing');

		var field_name = $(this).find(':selected').data("field"),
			direction = $(this).find(':selected').data("direction"),
			type = $(this).find(':selected').data("type"),
			number = $(this).find(':selected').data("number"),
			page_layout = $("main").data("layout"),
			page_id = $("main").data("id");


		$.ajax({
			type: 'POST',
			url:  ajax_file + '.php',
			data:({sort:{field_name:field_name, direction:direction, type:type, number:number, page_layout:page_layout, page_id:page_id},page_id:page_id}),
			dataType: 'html',
			success: function(html) {
				if($('.houses-filter__list .houses-filter__map-switch').hasClass('active')){
					return false;
				}

				$('.ajax-content-here').html(html);
				// $('.ajax-content-here').fadeTo( 500, 1 ).css("height","");
				/*
				$('.loader-panel').fadeOut(function() {
					$ajaxContentHere.css('height', '');
				});
				*/

				var selectedFields = filters_selected_fields().serialize();
				var selectedSort = sort_selected();

				if (selectedSort.length > 0) {
					if (selectedFields.length > 0) selectedFields += "&sort=" + selectedSort;
					else selectedFields = "sort=" + selectedSort;
				}
				if (selectedFields.length == 0) {
					history.pushState(null, null, window.location.protocol + '//' + window.location.hostname + window.location.pathname);
				}
				else {
					history.pushState(null, null, '?' + selectedFields);
				}

				fadeLoader('out', function() {
					$ajaxContentHere.css('height', '');
				});
				number_new_homes_showing();
				propertyItemSlider();
				$('.houses-filter__panel-switch').removeClass('ajaxing');
			}
		});
	});

	//----prevent clicking on filters when ajaxing------//
	$('.house-and-land-landing-page .main-part .filters label').click(function(e){
		if($('.houses-filter__panel-switch').hasClass('ajaxing')){
			e.preventDefault();
			return false;

		}
	});
	$('.house-and-land-landing-page .main-part .filters select').on('mousedown', function(e) {
		if($('.houses-filter__panel-switch').hasClass('ajaxing')){
			e.preventDefault();
			this.blur();
			window.focus();
		}
	});


	//filtering
	$('.new-homes .main-part, .house-and-land-landing-page .main-part, .readybuilt-landing .main-part, body.gallery .main-part, .display-for-sale .main-part').on('change','.filters input, .filters select',function(){
		if($(window).width() < 992){
			$(this).addClass('updated');
			var inputs = [];
			$(' .main-part .filters input, .main-part .filters select').each(function(){
				if($(this).hasClass("updated")){
					inputs.push(this);
				}
			});

			var page_layout = $("main").data("layout"),
				page_id = $("main").data("id");


			var filter_array = build_filter_array(inputs,page_layout,page_id);

			$('.houses-filter__sidebar .filter-prevent-overlay').show();
			check_multiple_filters_ajax(page_id, filter_array,"true");

			$('.houses-filter__footer-sticky .houses-filter__button-search').removeClass("just_reset");

		}else{
			if($('.houses-filter__panel-switch').hasClass('ajaxing')){
				return false;
			}
			//animation and adding classes
			$('.filters').addClass("updated");
			$('.houses-filter__panel-switch').addClass('ajaxing');

			fadeLoader('in', function() {
				$('.tabs-tertiary__tab-active').css('height', $('.tabs-tertiary__tab-active').height());
			});

			//setting varaibles
			var response_type = "html";

			if($('body').hasClass('house-and-land-landing-page') || $('body').hasClass('readybuilt-landing')) {
				var ajax_file = $('.tabs-tertiary__tab-active').data("file");

				if($('.tabs-tertiary__tab-active').data("response-type")){
					response_type = 'json';
				}

			}else{
				var ajax_file = '/pages/components/'+$('.ajax-content-here').data("file");
			}

			var thisObj = this;

			// ready built filtering
			if ($('body').hasClass('readybuilt-landing')) {
				if ($(this).attr('name') == 'rb_region') {
					var thisRegion = $(this).val();
					$.ajax({
						type: 'POST',
						url: '/ajax/rb_suburbs_estates.php',
						data: ({ region: thisRegion }),
						dataType: 'json',
						success: function(response) {
							if (response.status == 'ok') {
								if (Object.keys(response.results.suburbs).length > 0) {
									// clear out the suburbs dropdown list
									$('.suburb_select').html('');
									$('.suburb_select').append('<option value="">Any</option>');
									$.each(response.results.suburbs, function(index, thisSuburb) {
										$('.suburb_select').append('<option value="' + thisSuburb.suburb + '">' + thisSuburb.suburb + '</option>');
									});
								}
								if (Object.keys(response.results.estates).length > 0) {
									// clear out the estates list
									$('.estate_select').html('');
									$('.estate_select').append('<option value="">Any</option>');
									$.each(response.results.estates, function(index, thisEstate) {
										$('.estate_select').append('<option value="' + thisEstate.estate + '">' + thisEstate.estate + '</option>');
									});
								}

								filter_prodructs_grid([thisObj],response_type,ajax_file,false);
							}
						}
					});
				}

				/*
				if ($(this).attr('name') == 'rb_suburb') {
					var thisSuburb = $(this).val();
					$.ajax({
						type: 'POST',
						url: '/ajax/rb_suburbs_estates.php',
						data: ({ suburb: thisSuburb }),
						dataType: 'json',
						success: function(response) {
							if (response.status == 'ok') {
								if (Object.keys(response.resutls.estates).length > 0) {

								}
							}
						}
					});
				}
				*/
				if ($(this).attr('name') == 'rb_suburb') {
					var thisRegion = $('.filters-region input[type=radio]:checked').val(),
						thisSuburb = $(this).val();
					$.ajax({
						type: 'POST',
						url: '/ajax/rb_suburbs_estates.php',
						data: ({ region: thisRegion, suburb: thisSuburb }),
						dataType: 'json',
						success: function(response) {
							if (response.status == 'ok') {
								if (Object.keys(response.results.estates).length > 0) {
									// clear out the estates list
									$('.estate_select').html('');
									$('.estate_select').append('<option value="">Any</option>');
									$.each(response.results.estates, function(index, thisEstate) {
										$('.estate_select').append('<option value="' + thisEstate.estate + '">' + thisEstate.estate + '</option>');
									});
								}

								filter_prodructs_grid([thisObj],response_type,ajax_file,false);
							}
						}
					});
				}
			}

			if ($(this).attr('name') == 'hl_region') {
				
				$.ajax({
					type: 'POST',
					url: '/ajax/regions_get_suburbs.php',
					data: ({ region: $(this).attr('data-region-name') }),
					dataType: 'json',
					success: function(response) {
						if (response.status == 'ok') {
							if(!$('body').hasClass('general')){
							
								$('.suburb_select option').remove();
								$('.suburb_select').append('<option value="">Any</option>');
								$('.estate_select option').remove();
								$('.estate_select').append('<option value="">Any</option>');
	
								if (typeof response.results == 'object') {
									if (response.results.suburbs.length > 0) {
										for (s in response.results.suburbs) {
											var thisSuburb = response.results.suburbs[s];
											var currentSuburbsList = $.map($('.suburb_select option'), function(option) { return option.value; });
											if (currentSuburbsList.includes(thisSuburb.suburb) === false) {
												$('.suburb_select').append('<option value="' + thisSuburb.suburb + '">' + thisSuburb.suburb + '</option>');
											}
										}
									}
									if (response.results.estates.length > 0) {
										for (e in response.results.estates) {
											var thisEstate = response.results.estates[e];
											var currentEstatesList = $.map($('.estate_select option'), function(option) { return option.value; });
											if (currentEstatesList.includes(thisEstate.estate) === false) {
												$('.estate_select').append('<option value="' + thisEstate.estate_id + '">' + thisEstate.estate + '</option>');
											}
										}
									}
								}
							
							}
							filter_prodructs_grid([thisObj],response_type,ajax_file,false);
							
							
						}
					}
				});
			}

			//gallery response type override
			if($('body').hasClass('gallery'))response_type = "json";

			var page_layout = $("main").data("layout"),
				page_id = $("main").data("id"),
				parent = $(this).parents(".filter__container"),
				field_name = parent.data("field");

			if ($(this).attr('name') != 'hl_region' && $(this).attr('name') != 'rb_region' && $(this).attr('name') != 'rb_suburb') {
				filter_prodructs_grid([this],response_type,ajax_file,false);
			}
		}

	});

	function close_mobile_filter(){
		//$('.houses-filter__sidebar-mobile .overlay').fadeTo( 500, 0,function(){$('.houses-filter__sidebar-mobile .overlay').hide() } );

		fadeLoader('out',function(){$("body").removeClass('no-scroll');});

	}

	$('.houses-filter').on('click', ' .houses-filter__button-search', function() {
		fadeLoader('in');

		$('div.houses-filter__footer-sticky').removeClass('open');
		$('div.houses-filter__sidebar-mobile').removeClass('open').css('display', '');




		if($(this).hasClass("just_reset")){
			close_mobile_filter();
		}else{
			//setting varaibles
			var response_type = "html";

			if($('body').hasClass('house-and-land-landing-page')){
				var ajax_file = $('.tabs-tertiary__tab-active').data("file");

				if($('.tabs-tertiary__tab-active').data("response-type")){
					response_type = 'json';
				}

			}else{
				var ajax_file = '/pages/components/'+$('.ajax-content-here').data("file");
			}

			//gallery response type override
			if($('body').hasClass('gallery'))response_type = "json";

			var inputs = [];
			$(' .main-part .filters input, .main-part .filters select').each(function(){
				if($(this).hasClass("updated")){
					inputs.push(this);
				}
				$(this).removeClass("updated")
			});

			//$('.houses-filter__panel-switch').addClass('ajaxing');
			//$('.houses-filter__sidebar-mobile .overlay').show().fadeTo( 500, 0.8 );

			filter_prodructs_grid(inputs,response_type,ajax_file,true);
		}





	});
	var grid_html = "";

	function filter_prodructs_grid(inputs,response_type,ajax_file,mobile){
		var page_layout = $("main").data("layout"),
			page_id = $("main").data("id");


		var filter_array = build_filter_array(inputs,page_layout,page_id);

		// console.log(filter_array);

		//check multiple filters ajax
		if($('body').hasClass('gallery')){
			$('.houses-filter__sidebar .filter-prevent-overlay').addClass("on");

			check_multiple_filters_ajax(page_id, filter_array);
		}

		$.ajax({
			type: 'POST',
			url: ajax_file+'.php',
			data:({filter:filter_array, page_id:page_id}),
			dataType: response_type,
			success: function(html) {

				if($('.houses-filter__list .houses-filter__map-switch').hasClass('active')){
					//clear mark and clusters
					$('.infoBox').remove();
					if(typeof clusterer != 'undefined'){
						clusterer.clearMarkers();
					}
		            markers = [];

					set_markers(html);

				}else{
					var temp_ajax_height = $('.ajax-content-here').height();


					//gallery reinit isotope
					if($('body').hasClass('gallery')){
						insert_new_gallery_html(html);
					}else{
						$('.ajax-content-here').css("height",temp_ajax_height).html(html);
						number_new_homes_showing();
					}
					var extra_offset = 0;
					if($(window).width()<767){
						extra_offset = 40;
					}

					$('html, body').animate({scrollTop: $('.main-part').offset().top - extra_offset}, 300);
					//console.log($('.ajax-content-here').height());


				}

				var selectedFields = filters_selected_fields();
				selectedFields = selectedFields.serialize();

				var selectedSort = sort_selected();
				if (selectedSort.length > 0) {
					if (selectedFields.length > 0) selectedFields += "&sort=" + selectedSort;
					else selectedFields = "sort=" + selectedSort;
				}

				// push this onto history
				if (selectedFields.length == 0) {
					history.pushState(null, null, window.location.protocol + '//' + window.location.hostname + window.location.pathname);
				}
				else {
					history.pushState(null, null, '?' + selectedFields);
				}

				if(mobile){
						//number_new_homes_showing();
						fadeLoader('out',function(){
							$("body").removeClass('no-scroll');
						});






				}else{
					/*$('.tabs-tertiary__tab-active').fadeTo( 500, 1,function(){
						Waypoint.refreshAll();
					}).css("height","");
					*/
					/*
					$('.loader-panel').fadeOut(function() {
						Waypoint.refreshAll();
						$('.tabs-tertiary__tab-active').css('height', '');
					});
					*/
					fadeLoader('out', function() {
						Waypoint.refreshAll();
						$('.tabs-tertiary__tab-active').css('height', '');
					});
				}
				$('.houses-filter__panel-switch').removeClass('ajaxing');
				propertyItemSlider();
			}
		});

	}

	function filters_selected_fields() {
		return $('.filters form :input').filter(function(index, element) {
			var checkMultiple = "";
			if(!$(element).is(":visible")) {
				if($(window).width() > 767) {
					if($(element).parents("fieldset").find(".visible-xs").find($(element)).length == 1) {
						checkMultiple = "true";
					}
				}
				else {
					if($(element).parents("fieldset").find(".visible-md").find($(element)).length == 1) {
						checkMultiple = "true";
					}
				}
			}
			return $(element).val() != '' && checkMultiple != "true";
		});
	}

	function sort_selected() {
		var $sort = $('#sort_by'),
			returnVar = "";

		if (typeof $sort != 'undefined') {
			var sortVal = $sort.val();
			if (typeof sortVal != 'undefined') {
				if (sortVal.length > 0) {
					returnVar = sortVal.replace('-', '').replace(')', '').replace('(', '-');
				}
			}
		}
		return returnVar;
	}


	function insert_new_gallery_html(html){
		$('.grid h3').remove();
		grid_html = html.html;
		$('#total_images_count').attr("data-count",html.total_rows);

		//updating load more button
		if(parseInt(html.load_more) > 0){
			$('button.load-more').removeClass('hidden').attr("data-count",parseInt(html.load_more));
		}else{
			$('button.load-more').addClass('hidden').attr("data-count",0);
		}

		$('.num_results').text("loading...");
		if(html.html != null){


			if($('.grid-item').length){
				$grid.isotope( 'remove', $('.grid-item') );
			}else{
				insert_grid_item_div_with_load(grid_html);
			}

			//empyting modal
			$('#modal-gallery .slider-images-secondary__slides').slick('slickRemove',null, null, true);
			$('#modal-gallery .large_image').html('');

			//putting in new modal images
			if(html.modal_large_images != null && html.modal_slider != null){
				$('#modal-gallery .large_image').append(html.modal_large_images);
				$.each(html.modal_slider, function(key,value){

					$('#modal-gallery .slider-images-secondary__slides').slick('slickAdd',value);
				});
				$('#modal-gallery .mobile-text-slider .container').html('');
				$.each(html.text_container_array, function(key,value){

					$('#modal-gallery .mobile-text-slider .container').append(value);
				});
			}
			//number_new_homes_showing();
		}else{
			$('button.load-more').addClass('hidden').attr("data-count",0);
			$grid.isotope( 'remove', $('.grid-item') );
			$('.num_results').text("showing 0 results");
			$('.houses-filter__sidebar .filter-prevent-overlay').removeClass("on");
			$('.grid').append(html.message);
		}
	}

	function insert_grid_item_div_with_load(grid_html){
		var total_count = parseInt($('#total_images_count').attr("data-count")),
			load_more_count = parseInt($('button.load-more').attr("data-count"));

		$(grid_html).each(function(){
			var grid_item = $(this);

			  var bg = grid_item.css('background-image');
			  bg = bg.replace('url(','').replace(')','').replace(/\"/gi, "");
			  load_bg_images(bg, function(){
				  $grid.isotope( 'insert', grid_item );
				  number_new_homes_showing();
				  //console.log($('.grid .grid-item').length, (total_count-load_more_count));
				  if($('.grid .grid-item').length == (total_count-load_more_count)){
					   $('.houses-filter__sidebar .filter-prevent-overlay').removeClass("on");

					  ini_overlay_counter();

					  Waypoint.refreshAll();


				  }

			  });
		});
	}

	function load_bg_images(src, callback) {
	    var bg_image = new Image();
	    bg_image.onload = callback;
	    bg_image.src = src;
	   // console.log(bg_image);
	}

	if (typeof $grid != 'undefined') {
		$grid.on( 'removeComplete', function( event, removedItems ) {
				//console.log(grid_html);
			$('.grid-item').remove();
			//console.log(grid_html)
			if (typeof grid_html !== 'undefined' && grid_html != "" && grid_html != null) {
				insert_grid_item_div_with_load(grid_html);

			}

		} );
	}

	function build_filter_array(inputs,page_layout,page_id){
		var filter_builder_array = [];

		$.each(inputs,function(key,input){
			var	parent = $(input).parents(".filter__container"),
			field_name = parent.data("field");

			if(parent.hasClass('range__2fields')){
				//setting variables
				var min_input = parent.find('.min'),
					max_input = parent.find('.max'),
					operator = "",
					type = "",
					value = "";

				if(min_input.val() != "" && max_input.val() != ""){
					operator = "range",
					type = parent.data("type"),
					value = min_input.val()+"-"+max_input.val();
				}else if(min_input.val() != ""){
					operator = ">=",
					type = parent.data("type"),
					value = min_input.val();
				}else if(max_input.val() != ""){
					operator = "<=",
					type = parent.data("type"),
					value = max_input.val();
				}

				//hiding options
				if($(input).hasClass("min")){
					var min_value = parseInt(min_input.val());

					max_input.find("option").each(function(){
						if(min_value >= parseInt($(this).val())){
							$(this).addClass('hide');
						}else{
							$(this).removeClass('hide');
						}
					});
				}else if($(input).hasClass("max")){
					var max_value = parseInt(max_input.val());

					min_input.find("option").each(function(){
						if(max_value <= parseInt($(this).val()) ){
							$(this).addClass('hide');
						}else{
							$(this).removeClass('hide');
						}
					});
				}

			}else{
				var	operator = "",
					type = parent.data("type"),
					value = $(input).val();

					if($(input).data("operator")){
						operator = $(input).data("operator");
					}else{
						operator = parent.data("operator");
					}


					if(parent.hasClass("multiple-checkbox")){

						var add_divider = false,
							value = "";

							parent.find("input:checked").each(function(){
								if(add_divider){
									value += "|"
								}else{
									add_divider = true;
								}
								value += $(this).val();
							});
					}
			}
			filter_builder_array.push({field_name:field_name, value:value, operator:operator, type:type, page_layout:page_layout, page_id:page_id});
		});

		return filter_builder_array
	}

	$('.new-homes .main-part, .house-and-land-landing-page .main-part, .readybuilt-landing .main-part, .gallery .main-part, .display-for-sale .main-part').on('click','.filters-advanced-search__reset, .houses-filter__button-reset',function(e){
		e.preventDefault();
		if($('.houses-filter__panel-switch').hasClass('ajaxing')){
			return false;
		}
		fadeLoader('in');
		var response_type = "html";

		if($('body').hasClass('house-and-land-landing-page') || $('body').hasClass('readybuilt-landing')){
			var ajax_file = $('.tabs-tertiary__tab-active').data("file");

			if($('.tabs-tertiary__tab-active').data("response-type")){
				response_type = 'json';
			}

		}else{
			var ajax_file = '/pages/components/'+$('.ajax-content-here').data("file");
		}

		$('.filters').addClass("updated");
		$('.houses-filter__panel-switch').addClass('ajaxing');

		// $('.tabs-tertiary__tab-active').fadeTo( 500, 0.2 ).css("height",$('.tabs-tertiary__tab-active').height());
		/*
		$('.loader-panel').fadeIn(function() {
			$('.tabs-tertiary__tab-active').css('height', $('.tabs-tertiary__tab-active').height());
		});
		*/
		fadeLoader('in', function() {
			$('.tabs-tertiary__tab-active').css('height', $('.tabs-tertiary__tab-active').height());
		});

		$('.houses-filter__footer-sticky .houses-filter__button-search').addClass("just_reset");

		var	page_layout = $("main").data("layout"),
			page_id = $("main").data("id");

		//gallery response type override
		if($('body').hasClass('gallery')){
			response_type = "json";
			$('.houses-filter__sidebar .filter-prevent-overlay').addClass("on");

			check_multiple_filters_ajax(page_id, [{value:"RESET"}]);
		}


		$.ajax({
			type: 'POST',
			url: ajax_file+'.php',
			data:({filter:[{value:"RESET", page_layout:page_layout, page_id:page_id}]}),
			dataType: response_type,
			success: function(html) {
				if($('.houses-filter__list .houses-filter__map-switch').hasClass('active')){
					//clear mark and clusters
					$('.infoBox').remove();
					if(typeof clusterer != 'undefined'){
						clusterer.clearMarkers();
					}

		            markers = [];

					set_markers(html);
				}else{
					if($('body').hasClass('gallery')){
						insert_new_gallery_html(html);

					}else{
						$('.ajax-content-here').html(html);
						number_new_homes_showing();
					}
				}

				//reset checkboxs
				$(".filters input[type=checkbox]").prop('checked', false);
				$(".filters .jstyling-checkbox").removeClass('active');

				//reset radio buttons
				$(".filters input[type=radio]").prop('checked', false);
				$(".filters input[type=radio]:first-child").prop('checked', true);

				//reset radio buttons
				$(".filters input[type=text]").val('');

				//collections edit
				$($(".filters input[type=radio][name=collections]")[0]).prop('checked', true);
				$($(".filters input[type=radio][name=nh_collection_id]")[0]).prop('checked', true);
				//reset select boxes
				$(".filters select").each(function(){
					$(this)[0].selectedIndex = 0;
				});

				//unhide options
				$(".filters select option").removeClass('hide');



				//remove all estates on h&l
				//$('.suburb_select  option:first-child').attr("selected", "selected");
				$('.suburb_select, .estate_select').val("").trigger("change");

				var selectedSort = sort_selected();
				if (selectedSort.length > 0) history.pushState(null, null, '?sort=' + selectedSort);
				else history.pushState(null, null, window.location.protocol + '//' + window.location.hostname + window.location.pathname);

				/*$('.tabs-tertiary__tab-active').fadeTo( 500, 1,function(){
					Waypoint.refreshAll();
				}).css("height","");
				*/
				/*
				$('.loader-panel').fadeOut(function() {
					Waypoint.refreshAll();
					$('.tabs-tertiary__tab-active').css('height', '');
				});
				*/
				fadeLoader('out', function() {
					Waypoint.refreshAll();
					$('.tabs-tertiary__tab-active').css('height', '');
				});
				$('.houses-filter__panel-switch').removeClass('ajaxing');
				propertyItemSlider();
			}
		});

		return false;
	});
	//----------- new homes series -----------//
	var $start_matrix_array = Array(1,0,0,1);
	var zoom_increment = 0.3;

	if ($("html").hasClass("ie")){
		zoom_increment = 0.15;
	}

	/*
	$('.design').each(function(){
		var $pan_zoom = $(this).find('.pan-zoom');
		  	$pan_zoom.panzoom({
			    $zoomIn: $(this).find(".zoom-in"),
			    $zoomOut: $(this).find(".zoom-out"),
				panOnlyWhenZoomed: true,
			    minScale: 1,
			    increment: zoom_increment,
			    contain: 'inverse'
			});

		  $pan_zoom.on('panzoomchange', function(e, panzoom, transform) {

			  $temp_array = Array();
			  for (var i = 0; i < 4; ++i) {
			    $temp_array.push(parseFloat(transform[i]));
			  }

			  if(arraysEqual($start_matrix_array , $temp_array)){
				  $pan_zoom.panzoom("setMatrix", [ 1, 0, 0, 1, 0, 0 ]);
			  }
			});
	});
	*/

	$('.design_selectors h4').click(function(){
		$('.design, .design_selectors h4').removeClass('active');
		$(this).addClass('active');
		$('.design[data-id='+$(this).data("id")+']').addClass("active");
	});

	$('.view-full-screen').click(function(e){
		e.preventDefault();
		$('body').addClass('no-scroll');

		if ($('html').hasClass('ie')) {
			// find floorplan that's active
			var $activeFloorplan = $('.zoom-in-modal .right.block .floorplan.active');
			if ($activeFloorplan.length > 0) {
				pageID = $activeFloorplan.attr('id').replace('zoomfloorplan-', '');
				svgOnLoad('zoom_svg' + pageID);
			}
		}
		$('.zoom-in-modal .center .right.block').css('visibility', 'visible');
		$('.zoom-in-modal').css("visibility","visible").fadeTo(500,1);

		return false;
	});

	$('.new-homes__modal .close__zoom').click(function(){
		$('body').removeClass('no-scroll');
		$(this).parents('.new-homes__modal').css("visibility","hidden").fadeTo(500,0);
		$('.zoom-in-modal .center .right.block').css('visibility', 'hidden');
	});

	$('.new-homes_series .toggle-diclaimer, .house-and-land .toggle-diclaimer, .readybuilt .toggle-diclaimer, .display-for-sale_detail .toggle-diclaimer').click(function(){
		$('.disclaimer-modal').css("visibility","visible").fadeTo(500,1);
	});

	$('.new-homes_series #gallerySection .nav-secondary a, .house-and-land #gallerySection .nav-secondary a, .display-for-sale_detail #gallerySection .nav-secondary a, .readybuilt_lot #gallerySection .nav-secondary a').click(function(e){
		e.preventDefault();
		$('#gallerySection .nav-secondary li.active').removeClass("active");
		$(this).parent().addClass("active");

		$("#gallerySection .section__body").removeClass("active");
		$("#gallerySection .section__body."+$(this).data("class")).addClass("active");



		var maxWidthContainer = $('#gallerySection .section__body.active .slider-images ').width();
		$('#gallerySection .section__body.active .slider-images .slides .slide').each(function(){
			$(this).css("max-width",maxWidthContainer);
			//console.log($(this));
		})
		if($('#gallerySection .section__body.active .slider-images .slides').hasClass('slick-initialized')){
			$('#gallerySection .section__body.active .slider-images .slides').slick('setPosition');

		}


				//$(window).scroll();
		return false;
	});
	//----------- careers -----------//
/*
	if($('.careers-iframe-container iframe').length){

		$('.careers-iframe-container iframe').click(function(){
			console.log($(this));
			$("html, body").animate({ scrollTop: $(this).offest().top});
		});
	}

*/
	//-----------misc functions-----------//
	$('.houses-filter__sidebar h3').click(function(){
		if($(window).width()>=992 && $(window).width()<=1200){
			$('.hidden-group-filters-advanced').show();
			$('.filters-advanced-search__advanced-search').addClass('open');
			$('.houses-filter__sidebar, .houses-filter__footer-sticky').toggleClass('open');

		}
	});


	// mouse enters main nav + links
	$('.header .languages').mouseenter(function(){
		 $( " header .languages .slideDown" ).stop().slideDown();
	});

	// mouse leaves main nav + links
	$('.header .languages, header .languages .slideDown').mouseleave(function(e){

		if(!$(e.toElement).hasClass("languages")){
			$( " header .languages .slideDown" ).stop().slideUp();
		}

	});





	sidebar_filter_height_scroll();
	$(window).resize(function() {
		sidebar_filter_height_scroll();
	});

	$('.general .feature img').matchHeight();

	$('.article .container :not(.anchor,br,img,hr,iframe,i,span.line,object):empty').remove();

	function scrollToAnchor($hash){
		var header_height = 0;
		if($(window).width() < 1024){
			header_height = $('header.header').height() + 50;
		}

		var $anchor = $(escapeStr($hash));
		var $anchorOffset = $anchor.offset();

		var accordion = $anchor.parents('.accordion-secondary__section');

		if(accordion.length && !accordion.hasClass('accordion__section-expanded')){
			accordion.toggleClass('accordion__section-expanded')
					 	.find(".accordion-secondary__body").slideToggle(200, function(){
							$anchor = $(escapeStr($hash));
							$anchorOffset = $anchor.offset();
							if ($anchorOffset !== undefined) {
								$('html, body').animate({ scrollTop: $anchorOffset.top - header_height }, 500);
							}
						});
		}else{
			if ($anchorOffset !== undefined) {
				$('html, body').animate({ scrollTop: $anchorOffset.top - header_height }, 500);
			}
		}
	}

	$('.list-anchors a, a.animateScrollTo').click(function(e){
		e.preventDefault();

		var $href = $(this).attr('href').toString();

		var $hash = $href.substring($href.indexOf("#"));

		scrollToAnchor($hash)

		return false;
	});

	$(window).load(function() {
		if(window.location.hash) {
			var $href = window.location.hash.toString();
			var $hash = $href.substring($href.indexOf("#"));
			scrollToAnchor($hash)
		}
	});

	var getUrlParameter = function getUrlParameter(sParam) {
	    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
	        sURLVariables = sPageURL.split('&'),
	        sParameterName,
	        i;

	    for (i = 0; i < sURLVariables.length; i++) {
	        sParameterName = sURLVariables[i].split('=');

	        if (sParameterName[0] === sParam) {
	            return sParameterName[1] === undefined ? true : sParameterName[1];
	        }
	    }
	};

	$(window).load(function() {



		if($('body').hasClass('new-homes_series')){
			if(getUrlParameter('video') != undefined) {

				var $anchor = $('#gallerySection').offset();
				var header_height = 0;
				if($(window).width() < 1024){
					header_height = $('header.header').height() + 50;
				}

		        if ($anchor !== undefined) {
					$('html, body').animate({ scrollTop: $anchor.top - header_height }, 500);
				}

			}
		}


	});

	if($("iframe.auto-height").length){
		$(window).resize(function() {
			$("iframe.auto-height").each(function() {
				$(this).css("height",$(this).width()*.60 +"px")
			});
		}).resize();
	}

	var focusOnSelectTF = false;
	if($("body").hasClass("inclusions") || $("body#productLayout").length || $("body").hasClass("gallery")){
		focusOnSelectTF = true;
	}

		function ini_overlay_counter(){
			var $sliderImages = $('#modal-gallery .slider-images-secondary');
			var $sliderImagesContainer = $sliderImages.find('.slider-images-secondary__slides');
			var $sliderCurrent = $sliderImages.find('.slide-images__number');
			var $sliderTotal = $sliderImages.find('.slider-images__total');
			var $totalSlides = $sliderImagesContainer.find(".slider-images-secondary__slide:not(.slick-cloned)").length;
// 			console.log($totalSlides);
			$sliderTotal.text($totalSlides);
			$('#modal-gallery .slider-counter-styles .slider-images__total').text($totalSlides);

			$sliderImagesContainer.on('afterChange', function(event, slick, currentSlide){
				$sliderCurrent.text(currentSlide + 1);
				$('#modal-gallery .slider-counter-styles .slide-images__number').text(currentSlide + 1);
			});

		}
		 ini_overlay_counter();

		// Start images slider on genral content
		$('.slider-images-secondary__slides').slick({
			 variableWidth: true,
			 centerMode: true,
           	 responsive: true,

           	 focusOnSelect : focusOnSelectTF
          		});


		 if($('.inclusions .slider-images-secondary__slides').length){
				if($('.inclusions .slider-images-secondary__slide').not('.slick-cloned').length <= 3){
					$('.inclusions .slider-images-secondary__slides .slick-arrow').hide();
				}
			}

		$(window).on('load', function() {
			//re inti slick after resize to fixe image isues
			if ($(".slider-images-secondary__slides.slick-initialized:not(.no_reinit)").length ) {
	            $('.slider-images-secondary__slides').slick('reinit');

			}
		});

		$('.slider-images-secondary__slides .img-container').css("height",$('.slider-images-secondary__slides').height());

	//-----------news-----------
// 	$('#news-items').paginate({itemsPerPage: 5});

	
	//-----------news-----------
	$('.section-news__list').not('.building-design').on('click', 'a.load-more', function(e){
		var nextPage, selectedCategory, newsPageID;
		if (typeof $(this).attr('data-next-page') == 'string') {
			nextPage = $(this).attr('data-next-page');
		}
		if (typeof $(this).attr('data-category') == 'string') {
			selectedCategory = $(this).attr('data-category');
		}
		if (typeof $(this).attr('data-news-page') == 'string') {
			newsPageID = $(this).attr('data-news-page');
		}

		$(this).html('Loading...').css('opacity', '0.5').prop('disabled', true);

		$.ajax({
			type: 'POST',
			url: '/pages/components/news_list.php?page=' + nextPage,
			data: ({
				category: selectedCategory,
				newsPageID: newsPageID,
				page_id: newsPageID
			}),
			dataType: 'html',
			success: function(html) {
				$('a.load-more').remove();
				$('.section-news__list .col-md-9 #news-items').append(html);
			}
		});
		return false;
	});
	
	$('.building-design').on('click', 'a.load-more', function(e){
		var nextPage, selectedCategory, newsPageID;
		if (typeof $(this).attr('data-next-page') == 'string') {
			nextPage = $(this).attr('data-next-page');
		}
		if (typeof $(this).attr('data-category') == 'string') {
			selectedCategory = $(this).attr('data-category');
		}
		if (typeof $(this).attr('data-news-page') == 'string') {
			newsPageID = $(this).attr('data-news-page');
		}

		$(this).html('Loading...').css('opacity', '0.5').prop('disabled', true);

		$.ajax({
			type: 'POST',
			url: '/pages/components/blog_list.php?page=' + nextPage,
			data: ({
				category: selectedCategory,
				newsPageID: newsPageID,
				page_id: newsPageID
			}),
			dataType: 'html',
			success: function(html) {
				$('a.load-more').remove();
				$('.section-news__list .col-md-9 #news-items').append(html);
			}
		});
		return false;
	});


/*

	$('.news button.load-more').click(function(e){

		var load_more_array = $(this).attr("data-load-more").split(',');


		if(load_more_array.length > 10){
			$(this).attr("data-load-more",load_more_array.slice(10).join(","));
		}else{
			$(this).attr("data-load-more","");
			$(this).hide();
		}



		$.ajax({
			type: 'POST',
			url: '/pages/components/news_list.php',
			data:({load_more:load_more_array}),
			dataType: 'html',
			success: function(html) {

				$('.section-news__list .col-md-9 #news-items').append(html);
				$('.section-news__list .col-md-9 #news-items li').slideDown(800);
			}
		});



	});


	$('.section-news').on('click','.list-categories a',function(e){
	  e.preventDefault();
	  var category = $(this).text(),
	  	  page_id = $('.section-news').data("id"),
	  	  featured_id = $('section-news__group featured').data("id");

	  	$.ajax({
			type: 'POST',
			url: '/pages/components/news_list.php',
			data:({category:category,featured_id:featured_id, page_id:page_id}),
			dataType: 'html',
			success: function(html) {

				$('.section-news__list .col-md-9.ajax').fadeOut(300,function(){
					$(this).html(html).fadeIn(400);
					//$('#news-items').paginate({itemsPerPage: 5});
				})

			}
		});



	  return false;
	});
*/



});
function sidebar_filter_height_scroll(){
	var top_padding = 30;
	if($(".houses-filter__sidepanel").length > 0){
		$('.houses-filter__sidebar').css('max-height', $(window).height()-top_padding);
	}
}
function escapeStr(str)
	{
	    if (str)
	        return str.replace(/([ ;?%&,.+*~\':"!^$[\]()=>|\/@])/g,'\\$1');

	    return str;
	}
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length != b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function number_new_homes_showing() {
	var num_results = $('.ajax-content-here .property, .ajax-content-here .grid-item:not(.no-count)').length,
		num_results_text = "";


		if($('body').hasClass('house-and-land-landing-page') && $('.button-gold.load-more').attr("data-count") > 0){

			num_results_text = num_results+" of " + (parseInt($('.button-gold.load-more').attr("data-total-results")));
			if (num_results == parseInt($('.button-gold.load-more').attr("data-total-results"))) {
				$('.button-gold.load-more').hide();
			}

		}else if($('body').hasClass('gallery') && $('.button-gold.load-more').attr("data-count") > 0 && $('#total_images_count').attr("data-count")){
			num_results_text = num_results+" of " + (parseInt($('#total_images_count').attr("data-count")) );
		}else{
			num_results_text = num_results;
		}



		if(num_results > 1 || num_results == 0){
			num_results_text = "Showing "+ num_results_text+" results";
		}else{
			num_results_text = "Showing "+ num_results_text+" result";
		}

		if (num_results == 0) $('#sort_by').hide();
		else $('#sort_by').show();

		$('.num_results').text(num_results_text);


}

function ini_new_homes_collections(){
	if($(".new-homes .collections .property-item--newhomes").length){
		if($(window).width()>768){
			$(".new-homes .collections .property-item--newhomes").css("height",$(".new-homes .collections .property-item").height());
		}
		$(window).resize(function(){
			if($(window).width()>768){
				$(".new-homes .collections .property-item--newhomes").css("height",$(".new-homes .collections .property-item").height());
			}else{
				$(".new-homes .collections .property-item--newhomes").css("height","");
			}

		});

	}

}

function ini_new_homes_all_homes(){
		$(".filters-advanced-search__advanced-search").click(function(e){
			e.preventDefault();
			$(this).toggleClass('open');
			if ($('body').hasClass('new-homes')) {
				$('.hidden-group-filters-advanced').slideToggle({
					duration: 400, 
					start: function() {
						if ($(window).width() >= 1200) {
							jQuery(this).css('display', 'flex');
						}
						else {
							jQuery(this).css('display', 'block');
						}
					}
				});
			}
			else {
				$('.hidden-group-filters-advanced').slideToggle(function() {
					Waypoint.refreshAll();
				});
			}
			return false;
		});

		if( $('.custom-checkbox input[type=checkbox]').length )
		$.jStyling.createCheckbox($('.custom-checkbox input[type=checkbox]'));


		$('.all_homes').on('click','.property__view-more.active .home_info a', function(e){
			e.preventDefault();
			var location = $(this).attr("href"); //+"?grey_ids=" + $('.all_homes .property__view-more.active').data('grey-ids');
			window.location.href = location;

		});

		/* sticky filter options on scroll */
		$('.houses-filter').waypoint(function(direction) {
			//console.log(this);

			var columnWidth = $('.houses-filter__sidepanel').width(),
				windowWidth = $(window).width();

			if (windowWidth >= 1200) {
				if (direction == 'down') {
					$('.houses-filter__sidepanel').addClass('sticky').children('.houses-filter__sidebar').css({
						width: columnWidth
					});
				}
				else {
					$('.houses-filter__sidepanel').removeClass('sticky').children('.houses-filter__sidebar').css({
						width: 'auto'
					});
				}
			}
			else {
				$('.houses-filter__sidepanel').removeClass('sticky').children('.houses-filter__sidebar').css({
					width: 'auto'
				});
			}
		}, {
			offset: 25
		});

		number_new_homes_showing();



		// $('.houses-filter__list .row .property:last-child .property-item').waypoint(function(direction) {
		$('.houses-filter #special-offers-section').waypoint(function(direction) {
			var $housesFilter = $('.houses-filter'),
				$housesFilterSidePanel = $housesFilter.find('.houses-filter__sidepanel'),
				windowWidth = $(window).width();

			if (windowWidth >= 1200) {
				if (direction == 'down') {
					// var bottomPx = $housesFilter.height() * -1 + 18;
					var bottomPx = $housesFilter.height() * -1;
					$housesFilterSidePanel.addClass('sticky-bottom').children('.houses-filter__sidebar').css({
						top: 'auto',
						bottom: bottomPx
					});
				}
				else {
					$housesFilterSidePanel.removeClass('sticky-bottom').children('.houses-filter__sidebar').css({
						top: '',
						bottom: ''
					});
				}
			}
			else {
				$housesFilterSidePanel.removeClass('sticky-bottom').children('.houses-filter__sidebar').css({
					top: '',
					bottom: ''
				});
			}
		}, {
			offset: function() {
				if($("body").hasClass("gallery") || $('body').hasClass('new-homes')) {
					return $('.houses-filter__sidebar').height();
				}
				else {
					if ($('.hidden-group-filters-advanced').is(':visible')) return 700;
					else return 500;
				}

			}
		});
	}